import React, { useContext } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import classes from './HeaderControls.scss';
import { HeaderContext } from './Header';

export const BasketIcon = React.memo(() => {
    const { cartPending, itemCount } = useContext(HeaderContext);
    return (
        <Link to="/basket" className={classnames(classes.headerControl, classes.headerControlBasket, 'button--reset')}>
            <BasketWithCount pending={cartPending} count={itemCount} />
            <span className={classes.headerControlText}>Basket</span>
            <span className={classes.headerControlContent}>
                {itemCount > 0 ? (
                    <>
                        Basket
                        <small className={classes.headerControlMeta} data-test-id="BasketItemCount">
                            {cartPending ? '...' : itemCount}
                        </small>
                    </>
                ) : (
                    'Your basket is empty'
                )}
            </span>
        </Link>
    );
});

export const BasketWithCount: React.FC<{ pending: boolean; count: number; variant?: 'inverted' }> = (props) => {
    const { count, pending } = props;
    return (
        <>
            <span className={classes.basketIconDesktop}>
                <img src={require('../../../static/icons/basket-checkout-black.svg')} alt="checkout icon" />
            </span>
            <span className={classes.basketIconMobile}>
                <img src={require('../../../static/icons/basket-checkout.svg')} alt="checkout icon" />
            </span>
            <span
                className={classnames(classes.headerControlBasketCount, {
                    [classes.headerControlBasketCountInvert]: props.variant === 'inverted',
                })}
            >
                {pending ? '.' : count}
            </span>
        </>
    );
};
