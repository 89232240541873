import { Container, Wrapper } from 'src/components/Layout';
import React from 'react';
import { Link } from 'react-router-dom';
import { BACKGROUND_COLOR } from 'src/components/Header/constants/Header';

import { ButtonLink } from '../Button/ButtonLink';
import { paths } from '../../../../pwa/src/config.json';

import classes from './HeaderCheckout.scss';
import { Logo } from './Logo';

export const HeaderCheckout: React.FC = () => {
    const onClick = () => {
        if (window.location.href.includes('/checkout/payment') || window.location.href.includes('/checkout/success')) {
            location.replace(paths.basket.basket);
        }
    };

    return (
        <Wrapper element="header" bg={BACKGROUND_COLOR} className={classes.header} data-test-id="CheckoutHeader">
            <Container className={classes.headerContainer}>
                <Link to="/" className={classes.headerLogoLink}>
                    <Logo />
                </Link>
                <ButtonLink to={paths.basket.basket} variant="bold" className={classes.headerButton} onClick={onClick}>
                    Return to Basket
                </ButtonLink>
            </Container>
        </Wrapper>
    );
};
