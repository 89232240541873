import React, { useCallback, useEffect, useRef } from 'react';
import { merge, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { shallowEqual, useSelector } from 'react-redux';
import { useDeps } from 'src/hooks/useDeps';
import { StoreState } from 'src/types/global-types';
import { useSubject } from 'src/hooks/useSubject';
import { Button } from 'src/components/Button/Button';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';
import classnames from 'classnames';
import { BACKGROUND_COLOR } from 'src/components/Header/constants/Header';

import { Svg } from '../../Svg/Svg';

import classes from './Search.scss';

type Props = {
    trigger: Subject<string>;
};

export const navOpenSelector = (s: StoreState) => s.app.openNav;

export function useObservableSelector<T extends (...args: any[]) => any>(selector): Subject<ReturnType<T>> {
    const result = useSelector(selector, shallowEqual);
    const sub = useSubject<ReturnType<typeof result>>({ name: 'useObservableSelector' });
    useEffect(() => {
        sub.next(result);
    }, [result, sub]);
    return sub;
}

const SearchForm: React.FC<Props> = (props) => {
    const { trigger } = props;
    const { historyEvents$ } = useDeps();
    const navOpen$ = useObservableSelector(navOpenSelector);
    const ref = useRef<HTMLInputElement>();

    const onChange = useCallback(
        (event) => {
            trigger.next(event.currentTarget.value);
        },
        [trigger],
    );

    useEffect(() => {
        const pathChanges = historyEvents$;
        const navOpens = navOpen$.pipe(filter(Boolean));
        /**
         * All the events that should cause the search to be closed
         */
        const searchClose = merge(pathChanges, navOpens)
            .pipe(
                tap(() => {
                    if (ref.current) {
                        ref.current.value = '';
                    }
                    trigger.next('');
                }),
            )
            .subscribe();
        return () => {
            searchClose.unsubscribe();
        };
    }, [navOpen$, historyEvents$, ref, trigger]);

    const gtmSearch = useCallback(() => {
        fireGtm({ event: 'search', search_term: (ref.current && ref.current.value) || '' });
    }, []);

    return (
        <form
            className={classes.SearchForm}
            action="/catalogsearch/results"
            method="GET"
            autoComplete="off"
            data-test-id="SearchForm"
            onSubmit={gtmSearch}
        >
            <input
                className={classes.SearchInput}
                id="search"
                ref={ref as any}
                name="query"
                type="search"
                onChange={onChange}
                aria-label="Search Box"
                placeholder="Search (e.g. Polo Shirts).."
                data-test-id="SearchInput"
            />
            <Button
                className={classnames(classes.SearchButton, {
                    [classes.whiteBorder]: BACKGROUND_COLOR !== 'orange',
                })}
                type="submit"
                variant="bold"
                aria-label="Search"
            >
                <Svg name="search" />
            </Button>
        </form>
    );
};

export const CustomSearchForm = SearchForm;
