import React, { useContext } from 'react';
import classnames from 'classnames';
import { ConfigContext } from 'src/components/AppShell/ConfigProvider';

import classes from './HeaderControls.scss';

export const HeaderCallUs = () => {
    const { furniture } = useContext(ConfigContext);

    if (!furniture.headerPhoneNumber) return null;

    return (
        <a
            href={`tel:${furniture.headerPhoneNumber}`}
            className={classnames(classes.headerControl, classes.headerControlContact)}
        >
            <img src={require('../../../static/icons/phone.svg')} alt="phone icon" />
            <span className={classes.headerControlContent}>
                <small>Call us free on</small>
                {furniture.headerPhoneNumber}
            </span>
        </a>
    );
};
